<template>

    <v-layout d-flex column  class="fh"  id="item">
      <v-flex shrink>
        <v-breadcrumbs
        :items="breadcrumbs"
        >
        
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              @click.prevent="$router.replace(item.href)"
              :disabled="item.disabled"
            >
            <v-icon v-if="item.icon" size="20" color="primary">{{item.icon}}</v-icon>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-divider></v-divider>
        <v-progress-linear :indeterminate="loading"></v-progress-linear>
      </v-flex>
      <v-flex grow class="relative body" style="z-index: 0" >
        <div class="absolute fh fw  " >
          <div class="relative fh fw auto-y-overflow " ref="wraper">
            <v-form class="px-4 fh" style="margin-top: 12px;" ref="form" @submit.prevent="submit" :disabled="loading">
              <v-row class="fh"> 
                <v-col cols="12" sm="8">
                  <v-subheader>
                    <v-icon small>mdi-account-circle</v-icon>
                    <v-spacer></v-spacer>
                    {{ 'profile' | t }}
                    <v-spacer></v-spacer>
                  </v-subheader>
                  <v-divider class="mb-4"></v-divider>
                  <v-profile-img
                    class="mx-auto mb-12"
                    :value="user.photoURL"
                    @change="user.photoURL = $event"
                  ></v-profile-img>
                 <template v-for="(field, n) in fields">
               
                  <component  
                    
                    :key="n"
                    :is="field.component"
                    v-bind="fieldProps[field.name]"  
                    v-on="field.on"
                    v-model="user[field.name]"
                  ></component>
                 </template>

                </v-col>
                <v-divider vertical class="fh"  v-if="breakpoint.smAndUp"></v-divider>
                <v-col cols="12" sm="4" class="">
                  <v-subheader>
                    <v-icon small>mdi-cog</v-icon>
                    <v-spacer></v-spacer>
                    {{ 'settings' | t}}
                    <v-spacer></v-spacer>
                  </v-subheader>
               
                  <v-divider class="mb-4"></v-divider>
                 <template v-for="(field, n) in customClaimsFields">
                  <component  
                    
                    :key="n"
                    :is="field.component"
                    v-bind="fieldProps[field.name]"  
                    v-on="field.on"
                    v-model="customClaims[field.name]"
                  ></component>
                 </template>

                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>


      </v-flex>
      <v-flex shrink >
        <v-divider></v-divider>

        <v-toolbar dense flat >
          <v-btn  outlined rounded color="info" class="mx-1" @click="submit" :loading="loading" >
            {{ 'save' | t }}
          </v-btn>
          <v-btn  outlined rounded color="grey" class="mx-1" :disabled="loading" @click="$router.go(-1)" >
            {{ 'cancel' | t }}
          </v-btn>
       
        </v-toolbar>
      </v-flex>
    </v-layout>

</template>

<script>
const defaults = () => ({
        rounded: true,
        dense: true,
        outlined: true
      })

export default {
  name: 'UserView',
  data: () => ({
    loading: false,
    user:{
      email: '',
      emailVerified: false,
      phoneNumber: '',
      password: '',
      displayName: '',
      photoURL: null,
      disabled: false,
    },
    customClaims : { // user settings
      role: 'account'
    },
    fieldProps: {}
  }),
  watch: {
    '$route': {
      handler () {
        if (this.isNew)
          return
        
        this.loading = true
        this.$api.get('/users/'+this.uid).then(({ data }) => {
           const { 
            displayName,
            email,
            phoneNumber,
            photoURL,
            customClaims
          } = data
          this.user.displayName = displayName
          this.user.email = email
          this.user.phoneNumber = phoneNumber
          this.user.photoURL = photoURL
          
          this.customClaims = customClaims
        }).catch(this.handelError).finally(() => this.loading = false)

      },
      immediate: true
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          icon: 'mdi-home',
          disabled: false,
          href: '/dashboard',
        },
        {
          text: this.$t('users'),
          disabled: false,
          href: '/dashboard/users',
        },
        {
          text: this.isNew ? this.$t('add_user') : this.user.displayName,
          disabled: true,
        },
      ]
    }, 
    uid () {
      return this.$route.params.uid 
    },
    isNew () {
      return this.uid.trim().toLowerCase() == 'new'
    },
    customClaimsFields () {
      const self = this
      const { $t: __ } = this
      return this.buildFields([
        {
          name: 'role',
          label: __('role'),
          icon: 'mdi-account-circle',
          required: true,
          rules: self.getFieldRule('required'),
          items: [...this.usersRoles],
          on: {
            change (value) {
              const props = self.fieldProps.role
              const item =  props.items.find(item => item.value == value)
              if (self.$vuetify.rtl)
                props['prepend-inner-icon'] = item.icon
              else
                props['append-icon'] = item.icon
            }
          },
          component: () => import('vuetify/lib/components/VSelect')
        }
      ])
    },
    fields () {
      const self = this
      const { $t: __ } = this
      return this.buildFields([
        {
          name: 'displayName',
          label: __('name'),
          icon: 'mdi-label',
          required: true,
          rules: self.getFieldRule('required'),
          component: () => import('vuetify/lib/components/VTextField')
        },
        {
          name: 'phoneNumber',
          label:  __('phone'),
          icon: 'mdi-cellphone',
          component: () => import('vuetify/lib/components/VTextField')
        },
        {
          name: 'email',
          label:  __('email'),
          icon: 'mdi-email',
          required: true,
          rules: self.getFieldRule('required|email'),
          component: () => import('vuetify/lib/components/VTextField')
        },
        {
          name: 'password',
          label:  __('password'),
          icon: 'mdi-lock',
          required: true,
          rules: self.isNew ? self.getFieldRule('required') : [],
          component: () => import('@/components/VPassword')
        }
      ])
    }
  },
  methods: {
    submit () {
      const { form } = this.$refs
      if (!form.validate()) return
      let { user, customClaims, $api  } = this
      user = { ...user }
      if (!user.phoneNumber)
        delete user.phoneNumber
      else
        user.phoneNumber = '+972' + user.phoneNumber
      
      if (!user.photoURL)
        delete user.photoURL
      console.log(user);
      if (!this.isNew && !user.password)
        delete user.password
 
      const call = this.isNew ? $api.post('/users', { user, customClaims }) : $api.put('/users/'+this.uid, { user, customClaims })

      this.loading = true
      call.then(({ data }) => {
        const { displayName } = data
        this.notify(this.$t(this.isNew ? 'user_created_successfuly': 'user_updated_successfuly', displayName), {
            color: 'success',
            icon: 'mdi-check-circle'
          })
          this.$router.go(-1)
      })
     .catch(this.handelError).finally(() => this.loading = false)
    


    },
    buildFields (fields) {
      return fields.map(field => {
        const { rtl } = this.$vuetify
        const { icon, on, component } = field
        if (field.icon)
          delete field.icon
        if (field.on)
          delete field.on
        if (field.component)
          delete field.component
        if (rtl)
          field['prepend-inner-icon'] = icon
        else
          field['append-icon'] = icon
        
        const attrs = {...defaults(), ...field}
        this.fieldProps[field.name] = {...attrs}
        return { attrs, on, component, name: field.name }
      })
    }
  }
}
</script>

<style lang="scss">
#item {
  .table-section {
    max-height: 48px; 
    flex-basis: 48px; 
    z-index: 1;
  }
  .table-section.table-footer {
    max-height: 58px; 
    flex-basis: 58px; 
  }
  .container {
    padding: 0;
    z-index: 1;
  }
  .v-skeleton-loader__table-tfoot {
    padding: 5px;
  }

  .v-data-footer.mobile {
    .v-data-footer__select,
    .v-data-footer__pagination {
      margin: auto;
    }
    .v-data-footer__select{

      height: 48px;
    }
  }
}
</style>